body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body a {
  color: #fa7c20;
}

body a:hover, body a:focus {
  color: #c95705;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.section {
  padding: 100px 0;
}

.section.dark {
  background-color: #f7f7f7;
}

.section h1.ui.header {
  font-size: 40px;
  margin-top: 0;
  margin-bottom: 15px;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.section h1.ui.header .sub.header{
  font-size: 16px;
  font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: none;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 75px;
}
.section h2.ui.header {
  font-size: 24px;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 10px;
}
.section h3.ui.header {
  font-size: 18px;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.section h3.ui.header .circular.icon{
  font-size: 3em;
  margin-bottom: 10px;
}
.section h3.ui.header .sub.header{
  font-size: 14px;
  font-family: "Roboto Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: none;
  font-weight: normal;
  line-height: 20px;
}

.games .ui.card {
  box-shadow: none;
  border-radius: 0;
}
.games .ui.card .image {
  border-radius: 0 !important;
}
.games .ui.card .header {
  font-size: 18px;
  font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
  text-transform: none;
  font-weight: 700;
}
.games .ui.card .description {
  font-style: italic;
  font-size: 16px;
  font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.gallery .ui.card {
  box-shadow: none;
  border-radius: 0;
}
.gallery .ui.card .image {
  border-radius: 0 !important;
}
.gallery .ui.card .description {
  font-size: 14px;
  font-family: "Droid Serif","Helvetica Neue",Helvetica,Arial,sans-serif;
}
.prices {
  font-family: "Roboto Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
}

.prices .sub.header .icon {
  font-size: 0.65em;
  display: inline-block;
}

.text-muted {
  color: #777;
}
.google-maps iframe {
  width: 100%;
}

.register {
  min-height: 850px;
  background-color: #222;
  background-position: center;
  background-image: url("/images/map-image.png");
  background-repeat: no-repeat;
}
.register .h1 {
  color: #ddd;
}

.register {
  padding-top: 0;
  padding-bottom: 0;
}

.register form {
  padding-top: 100px;
  padding-bottom: 100px;
}

.register .ui.inverted.form .birthdate {
  margin-bottom: 0;
}

.register .ui.inverted.form .field.error label, .ui.inverted.form .field.error .ui.checkbox label {
  color: #e0b4b4;
}

.register .grecaptcha-badge {
  margin: 0 auto 15px;
}

.ui.button.verify-button {
  float: right;
  margin-right: 0;
}

.privacy h4.header {
  margin-top: 10px;
  margin-bottom: 0;
  margin-left: 20px;
}

.privacy h4.header + div {
  margin-left: 40px;
}

.privacy ul .header {
  margin-top:10px !important;
  margin-left: -20px;
  margin-bottom: 0;
}

.terms ol {
  list-style: lower-alpha;
}

.submit-contact {
  float: right;
}

.ui.table tr.fee td {
  font-style: italic;
  border-top: 1px solid rgba(34 ,36 ,38, 0.25);
}

.ui.table tr.sum td {
  font-weight: bold;
  border-top: 3px double rgba(34 ,36 ,38, 0.5);
}

.shopping-cart.ui.floating.label {
  top: 0;
  left: 75%;
}

.internal .section {
  padding: 50px 0;
}

#root .App .ui.menu .menu.mobile {
  border-left: 1px solid #2d2e2f;
  margin-left: auto !important;
}

@media only screen and (min-width: 900px) {
  #root .App .ui.menu .menu.mobile {
    display: none;
  }
}

@media only screen and (max-width: 899px) {
  #root .App .ui.menu .menu:not(.mobile) {
    display: none;
  }
  
  .ui.menu .item.head {
    padding-left: 8px;
  }
}